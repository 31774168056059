import { useFeatureFlag } from '@getvim/feature-flags-react';
import React, { FC, useEffect, useCallback } from 'react';
import { useGlobalState } from '../stores/GlobalStore';
import useEhrStateHandlers from '../hooks/useEhrStateHandlers';
import { Screens } from '../types';
import './App.less';
import SimpleLoading from './loading/SimpleLoading';
import WritebackLoading from './loading/WritebackLoading';
import { GlobalStateActionType } from '../stores/globalStore.types';
import { ReactComponent as WriteBackErrorSVG } from './writeback/write-back-error.svg';
import IframeRenderer from './iframe-renderer/IframeRenderer';
import { useEnrichLoggerMetadata } from '../hooks/useEnrichLoggerMetadata';

const App: FC = () => {
  useEhrStateHandlers();
  const { state, dispatch } = useGlobalState();

  const messageEventHandler = useCallback(
    async (message) => {
      if (message.data === 'referral-submit') {
        return dispatch({
          type: GlobalStateActionType.ON_SUBMIT,
        });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener('message', messageEventHandler);
    return () => window.removeEventListener('message', messageEventHandler);
  }, [dispatch, messageEventHandler]);

  const onClose = () => {
    return dispatch({
      type: GlobalStateActionType.ON_CLOSE, // TODO ON_CLOSE type should be there
    });
  };

  const { url, screen } = state;

  const [withTransparentBackground] = useFeatureFlag({
    flagName: 'withTrasnparentBackground',
    defaultValue: false,
  });

  const [shouldPreventRefreshIframe] = useFeatureFlag({
    flagName: 'optumReferralUtility.shouldPreventRefreshIframe',
    defaultValue: false,
  });

  const OptumSearchProviderContent = () => {
    return <>{url ? <iframe className="widget-frame-hub" src={url} /> : <ErrorLoadIframe />}</>;
  };

  useEnrichLoggerMetadata();

  const modesDisplay = {
    [Screens.None]: <div />,
    [Screens.Loading]: (
      <div className="frame-container-hub">
        <SimpleLoading />
      </div>
    ),
    [Screens.LoadingWriteBack]: <WritebackLoading />,
    [Screens.WriteBackError]: (
      <div className="frame-container-hub">
        <ErrorWriteBackFailed onClose={onClose} />
      </div>
    ),
    [Screens.OptumSearchProvider]: (
      <div className="frame-container-hub">
        <>
          {shouldPreventRefreshIframe ? (
            <IframeRenderer url={url} />
          ) : (
            <OptumSearchProviderContent />
          )}
        </>
      </div>
    ),
  };

  if (withTransparentBackground) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(255,255,255,0.15)',
          zIndex: -1,
        }}
      >
        <div style={{ zIndex: 10 }}>{modesDisplay[screen]}</div>
      </div>
    );
  }

  return modesDisplay[screen];
};

const ErrorLoadIframe: FC = () => (
  <div className="error-load-iframe">
    <div className="error-load-iframe-title">Something went wrong :(</div>
    <div className="error-load-iframe-text">Please refresh the page and try again</div>
    <img
      className="error-load-iframe-img"
      src="https://static.getvim.com/img/errorPageImg.png"
      alt="loading logo"
    />
  </div>
);

const ErrorWriteBackFailed = ({ onClose }) => (
  <div className="error-iframe">
    <WriteBackErrorSVG />
    <div className="error-iframe-title">Ooops...</div>
    <div className="error-iframe-text">
      Failed to update the referral. <br /> Please make sure to update it manually.
    </div>
    <div className="error-iframe-buttons-container">
      <button className="error-iframe-cancel-button" type="button" onClick={onClose}>
        Close
      </button>
    </div>
  </div>
);

export default App;
