import { useVimUserMetadata } from '@getvim/internal-vim-os-sdk/react';
import { logger } from '@getvim/vim-connect-logger';
import { useEffect } from 'react';
import { useFeatureFlag } from '@getvim/feature-flags-react';

export const useEnrichLoggerMetadata = (): void => {
  const [shouldAddLoggerMetadata] = useFeatureFlag({
    flagName: 'referral_utility.shouldAddLoggerMetadata',
    defaultValue: false,
  });
  const log = logger.scope('Optum Order Utility Client [useEnrichLoggerMetadata]');
  const { organization, ehrVendor, deviceId, user, userSessionId } = useVimUserMetadata();
  useEffect(() => {
    if (!shouldAddLoggerMetadata) {
      return;
    }
    try {
      log.info('Setting logger metadata', {
        deviceId,
        ehrUserName: user?.identifiers?.ehrUsername,
        organizationId: organization?.identifiers?.id,
        noPHI: true,
        userSessionId,
      });
      logger.setMetadata({
        deviceId: deviceId!,
        adapterName: ehrVendor,
        organizationId: organization?.identifiers?.id,
        organization_name: organization?.identifiers?.name,
        version: window?.$vim_environment?.version || '',
        windowId: '',
        ehrUserName: user?.identifiers?.ehrUsername,
      });
      logger.setUserMetadata({ userSessionId });
    } catch (error) {
      log.warning('Error setting logger metadata', { error: error, noPHI: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, ehrVendor, deviceId, userSessionId, shouldAddLoggerMetadata]);
};
