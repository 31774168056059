import { isEmpty } from 'lodash-es';
import {
  DiagnosisCodesResponse,
  ReferralUtilityResponseReferralResult,
} from '../../api/optum/types';
import { getNpiIdentifierResponse } from './getNpiByIdentifierResponse';

const NOT_RECOMMENDED_CODE = 'NOT_RECOMMENDED';
const OPTUM_FACILITY_NA_NAME = 'N/A';

export const formatReason = (referralReason: string) => {
  return referralReason.replaceAll(/<br \/>/gm, '\n');
};

export const getFormatResponseToNotes = async (
  response: ReferralUtilityResponseReferralResult,
): Promise<string> => {
  const {
    referralId = '',
    specialist,
    ruSpecialty: specialty,
    clinicalAlgorithm,
    type,
    beginDate,
    endDate,
    numberOfVisits,
    diagnosisCodes,
    referralReason,
    urgency,
  } = response;
  const { identifiers, firstName, lastName, address, phone, fax, clinicLocation } =
    specialist ?? {};

  const referralTitle = `OptimalCare - referral ID  ${referralId}`;

  const isNameAvailable = firstName !== OPTUM_FACILITY_NA_NAME;

  const targetSpecialist = noteTemplate('Target specialist:', {
    NPI: getNpiIdentifierResponse(identifiers),
    ...(isNameAvailable
      ? { Name: [...(lastName ? [lastName] : []), ...(firstName ? [firstName] : [])].join(' ') }
      : {}),
    'Practice name': clinicLocation,
    Specialty: specialty,
    Address: address,
    Phone: phone,
    Fax: fax,
  });

  const clinicalAlgorithmInformation = getClinicalAlgorithmResponse(clinicalAlgorithm);

  const referralInformation = noteTemplate('Referral information:', {
    Type: type,
    'Start date': beginDate,
    'End date': endDate,
    'Number of visits': numberOfVisits,
    Diagnosis: getDiagnosisCodesResponse(diagnosisCodes),
    Reason: referralReason ? formatReason(referralReason) : undefined,
    Urgency: urgency,
  });

  return [
    referralTitle,
    '',
    targetSpecialist,
    '',
    referralInformation,
    ...(clinicalAlgorithmInformation ? ['', clinicalAlgorithmInformation] : []),
  ].join('\n');
};

const noteTemplate = (title: string, data: Record<string, string | undefined>) => {
  const note = [title];
  for (const [info, value] of Object.entries(data)) {
    if (value) {
      note.push(`${info}: ${value}`);
    }
  }
  return note.join('\n');
};

const getDiagnosisCodesResponse = (diagnosisCodes: DiagnosisCodesResponse[] | undefined) => {
  if (!diagnosisCodes) return;
  return [
    '',
    ...diagnosisCodes.map(({ value, description }) => {
      return ` code: ${value} description: ${description}`;
    }),
  ].join('\n');
};

const getClinicalAlgorithmResponse = (
  clinicalAlgorithm: ReferralUtilityResponseReferralResult['clinicalAlgorithm'],
) => {
  const {
    clinicalAlgorithmResponses,
    code,
    reasonAlgorithmIgnored,
    reasonCommentAlgorithmIgnored,
  } = clinicalAlgorithm ?? {};

  const prefixText = !isEmpty(clinicalAlgorithmResponses) ? 'Clinical algorithm:' : undefined;

  const clinicalAlgorithmResult = clinicalAlgorithmResponses
    ?.map(
      ({ question, response }) =>
        ` Question: ${question}${response ? `\n Response: ${response}` : ''}`,
    )
    .join('\n');

  const notRecommendedReason =
    code === NOT_RECOMMENDED_CODE
      ? `\nReferral not recommended\nProvider's reason to proceed with the referral: ${
          reasonAlgorithmIgnored ?? 'N/A'
        }\nAdditional comments : ${reasonCommentAlgorithmIgnored ?? 'N/A'}`
      : undefined;

  return [prefixText, clinicalAlgorithmResult, notRecommendedReason].filter(Boolean).join('\n');
};
