import { EnrichedReferral } from '@getvim-os/types';
import {
  useApi,
  useResources,
  useResourcesWithPII,
  useAppState,
  useVimUserMetadata,
} from '@getvim/internal-vim-os-sdk/react';
import {
  ActivationStatus,
  ReferralWithoutPII,
  PatientWithoutPII,
  UpdatableReferral,
  PatientProblemList,
  Provider,
} from '@getvim/internal-vim-os-sdk/types';
import { useEffect, useState } from 'react';
import { logic } from '../logic';
import { useGlobalState } from '../stores/GlobalStore';
import { GlobalStateActionType } from '../stores/globalStore.types';
import { OptumReferralAppEventsEnum } from '../analytics/types/appEvents.types';
import { createLogger } from '../utils';
import { useAnalytics } from './useAnalytics';
import { ActionStatus, AppConfig, Screens, SelectAction } from '../types';
import { getProviderAnalyticsProperties } from '../analytics/parseProviderForAnalytics';
import { internalApi } from '../api';
import { getReferralMode, ReferralModeEnum } from '../logic/referral-request/referralMode';
import { removeNullsValueProps } from '../utils/filterNullValues';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import { buildReferralWriteback } from './buildReferallWriteback';

const logger = createLogger('useEhrStateHandlers');
/**
 * A custom hook to handle ehr state reactive logic
 */
const useEhrStateHandlers = () => {
  const { dispatch, state } = useGlobalState();
  const { ehrEvent } = useResources();
  const { ehrState } = useResourcesWithPII();
  const { user, organization } = useVimUserMetadata();

  const [isActive, setActive] = useState(false);
  const [currentReferral, setCurrentReferral] = useState<ReferralWithoutPII>();

  const [waitingForVimProviderEhrUpdate, setWaitingForVimProviderEhrUpdate] =
    useState<boolean>(false);

  const [ouAppLoaded, setOuAppLoaded] = useState<boolean>(false);
  const [submittedReferralsMap, setSubmittedReferralsMap] = useState<Map<string, string>>(
    new Map(),
  );
  const [appConfig, setAppConfig] = useState<AppConfig>();

  const { isAppOpen } = useAppState();
  const {
    setActivationStatus,
    autoPopup,
    pushNotification,
    setTooltipText,
    resourceUpdate,
    enableLaunchButtons,
  } = useApi();

  const { analyticsClient } = useAnalytics();
  const referral: ReferralWithoutPII | undefined = ehrState.referral;
  const patient: PatientWithoutPII | undefined = ehrState.patient;

  const [shouldSendAppEnabledEvent] = useFeatureFlag({
    flagName: 'referral_utility.shouldSendAppEnabledEvent',
    defaultValue: false,
    flagContext: {
      vimUserEmail: user?.identifiers?.ehrUsername,
      organizationId_string: organization?.identifiers?.id.toString(),
    },
  });

  const [shouldStopDisableEnableapp] = useFeatureFlag({
    flagName: 'referral_utility.shouldStopDisableEnableapp',
    defaultValue: false,
    flagContext: {
      vimUserEmail: user?.identifiers?.ehrUsername,
      organizationId_string: organization?.identifiers?.id.toString(),
    },
  });

  const setInlineButtonActive = (active: boolean) => {
    if (shouldSendAppEnabledEvent) {
      logger.info('Set app loaded Status', { appLoaded: active, noPHI: true });
      setOuAppLoaded(active);
    }
    enableLaunchButtons({ referral: { REFERRAL_SELECT_PROVIDER: active } });
  };

  /** Not used for now */
  // const debouncedPushNotification = useMemo(
  //   () =>
  //     debounce(() => {
  //       pushNotification.show({
  //         text: 'Use Order Utility to create your referral',
  //         notificationId: uuid(),
  //         timeoutInSec: 20,
  //         actionButtons: {
  //           rightButton: {
  //             text: 'Open App',
  //             buttonStyle: ButtonStyle.PRIMARY,
  //             openAppButton: true,
  //             callback: () => {},
  //           },
  //         },
  //       });
  //     }, 1500),
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [],
  // ); // 1.5 s delay

  useEffect(() => {
    internalApi.referralUtilityApi
      .getConfiguration()
      .then((data) => {
        const { configuration } = data;
        setAppConfig(configuration!);
      })
      .catch((error) => {
        logger.error(error);
      });
  }, []);
  // ----------------------------------------
  // Referral Saved Handler
  // ----------------------------------------
  useEffect(() => {
    (async () => {
      if (ehrEvent && appConfig?.optumDeployConfiguration) {
        const payload = ehrEvent.payload as { referral?: EnrichedReferral } | any;
        const type = ehrEvent.type as 'referralSaved' | 'string';
        if (type === 'referralSaved') {
          const referral = payload?.referral || (payload as EnrichedReferral);
          await logic.sendReferralSavedToOptum({
            referral,
            submittedReferralsMap,
            optumDeployConfiguration: appConfig?.optumDeployConfiguration,
          });
          setCurrentReferral(undefined);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ehrEvent, appConfig, submittedReferralsMap]);

  useEffect(() => {
    if (isAppOpen) {
      onOpen();
    } else {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppOpen]);

  // ----------------------------------------
  // Referral Handler
  // ----------------------------------------
  useEffect(() => {
    (async () => {
      /** Referral handler */
      if (referral && appConfig) {
        const referralContext = {
          referralId: referral.identifiers?.vimReferralId,
          ehrReferralId: referral.identifiers?.ehrReferralId,
          patientId: patient?.identifiers?.vimPatientId,
          insurance: patient?.insurance?.ehrInsurance,
        };
        logger.info('Before start process referral viewed event', {
          noPHI: true,
          appIsAlreadyActive: isActive,
          ...referralContext,
        });
        /** Open referral case */

        if (isActive) {
          /** If the optum iframe is opened the app does not track changes in ehr */
          return;
        }
        const { insurancesPlanList, supportAllInsurances, autoPopUpMode } = appConfig;
        logger.info('Starting to process referral viewed event', {
          noPHI: true,
          ...referralContext,
        });
        logger.info('Starting to process referral viewed event', { referral, patient });

        /** insurance does not support */
        const shouldDisplayWidget = logic.isPatientEligible({
          patient,
          insurancesPlanList,
          supportAllInsurances,
        });
        if (!shouldDisplayWidget) {
          logger.warning('Patient is not eligible', { noPHI: true, ...referralContext });
        }
        const isReferralReady = await logic.isReferralValid({ referral, patient: patient! });
        if (!isReferralReady) {
          logger.warning('Referral is not ready', { noPHI: true, ...referralContext });
        }

        /** referral is locked */
        const { basicInformation } = referral;

        logger.info('Got referral contect', {
          noPHI: true,
          ...referralContext,
          isLocked: basicInformation?.isLocked,
        });

        if (basicInformation?.isLocked) {
          setInlineButtonActive(false);
          setTooltipText('The referral is locked');
          return;
        }

        const prevReferral = { ...currentReferral };
        setCurrentReferral(referral);
        analyticsClient.track(OptumReferralAppEventsEnum.BUTTON_CALCULATED, {
          referral,
          button_displayed: shouldDisplayWidget,
          button_not_displayed_reason: !shouldDisplayWidget ? 'member not eligible' : undefined,
        });

        const currentProvider = referral.targetProvider;
        const previousProvider = prevReferral?.targetProvider;
        const didTargetProviderChange =
          currentProvider && JSON.stringify(currentProvider) !== JSON.stringify(previousProvider);

        const referralWasSwitched =
          referral.identifiers?.ehrReferralId !== prevReferral.identifiers?.ehrReferralId;
        const referralFirstOpened = !currentReferral;

        if (referralFirstOpened || referralWasSwitched) {
          logger.info('referral switched/opened for the first time - resetting optum referral id', {
            previousOptumId: state.optumIframeId,
            previousReferral: currentReferral,
            newReferral: referral,
            noPHI: true,
          });

          setWaitingForVimProviderEhrUpdate(false);
          // this.setState({
          //   optumIframeId: undefined,
          //   optumReferralId: undefined,
          // });

          //this.referralToUpdate = undefined;
        }

        if (didTargetProviderChange && !referralWasSwitched) {
          trackTargetProviderSelectedNative(previousProvider, currentProvider);
        }
        if (isReferralReady && shouldDisplayWidget) {
          const referralMode = await getReferralMode({ referral });
          logger.info('Before app and button activation', {
            noPHI: true,
            referralWasSwitched,
            referralFirstOpened,
            referralMode,
            ...referralContext,
          });
          setActivationStatus(ActivationStatus.ENABLED);
          setInlineButtonActive(true);
          logger.info('App and button acivater', {
            noPHI: true,
            ...referralContext,
          });
          /** referral was opened first time or another referral was opened */
          if (
            (referralWasSwitched || referralFirstOpened) &&
            ((referralMode === ReferralModeEnum.CREATE && autoPopUpMode?.create) ||
              (referralMode === ReferralModeEnum.EDIT && autoPopUpMode?.edit))
          ) {
            logger.info('Start autoPopup', {
              noPHI: true,
              ...referralContext,
            });
            autoPopup();
            await onOpen();
            logger.info('After autoPopup done', {
              noPHI: true,
              ...referralContext,
            });
          }
        }
      } else {
        /** Close referral case */
        logger.info('Starting to process referral closed event', {
          noPHI: true,
        });
        pushNotification.hide();
        setActivationStatus(ActivationStatus.DISABLED);
        setOuAppLoaded(false);
        setActive(false);
        setCurrentReferral(undefined);
        setWaitingForVimProviderEhrUpdate(false);
        dispatch({
          type: GlobalStateActionType.REFERRAL_CLOSED,
          payload: {
            optumIframeId: undefined,
            url: null,
            screen: Screens.None,
          },
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, referral, appConfig]);
  const [shouldFilterNullValues] = useFeatureFlag({
    flagName: 'referral_utility.shouldFilterNullValuesToWriteback',
    defaultValue: false,
  });

  const [shouldUseLeadingReachProvider] = useFeatureFlag({
    flagName: 'referral_utility.shouldUseLeadingReachProvider',
    defaultValue: false,
    flagContext: {
      vimUserEmail: user?.identifiers?.ehrUsername,
      organizationId_string: organization?.identifiers?.id.toString(),
    },
  });
  const [shouldUseLeadingReachProviderReferToName] = useFeatureFlag({
    flagName: 'referral_utility.shouldUseLeadingReachProviderReferToName',
    defaultValue: false,
    flagContext: {
      vimUserEmail: user?.identifiers?.ehrUsername,
      organizationId_string: organization?.identifiers?.id.toString(),
    },
  });

  const onReferralSubmit = async () => {
    if (!state.optumIframeId) {
      return;
    }
    const writeBackContext = {
      stateOptumIframeId: state.optumIframeId,
      deployConfig: appConfig?.optumDeployConfiguration,
      vimReferralId: referral?.identifiers?.vimReferralId,
    };
    setWaitingForVimProviderEhrUpdate(true);
    const data = await logic.referralResponse({
      referral,
      optumReferralId: state.optumIframeId,
      deployConfig: appConfig!.optumDeployConfiguration!,
    });
    if (!data || !data.referralId) {
      return logger.error("Didn't get a referral id from Optum response", {
        ...writeBackContext,
        referral: data,
        noPHI: true,
      });
    }
    logger.info(`got referral from optum after response`, {
      ...writeBackContext,
      referral: data,
      noPHI: true,
    });

    if (currentReferral?.identifiers?.vimReferralId) {
      setSubmittedReferralsMap(
        submittedReferralsMap.set(currentReferral.identifiers.vimReferralId, data.referralId),
      );
    } else {
      logger.warning('Failed adding submitted referral to map, vimReferralId is missing', {
        currentReferral,
        optumResult: data,
        noPHI: true,
      });
    }

    let referralToUpdate: UpdatableReferral = await logic.getUpdateReferralByQueryResponse(
      data,
      shouldUseLeadingReachProvider,
      shouldUseLeadingReachProviderReferToName,
    );

    if (shouldFilterNullValues) {
      logger.info('Filter null values from referralToUpdate');
      referralToUpdate = removeNullsValueProps(referralToUpdate);
    }
    logger.info('updating referral with new data', { ...writeBackContext, noPHI: true });
    const builder = resourceUpdate.referralBuilder();
    buildReferralWriteback(builder, referralToUpdate);

    try {
      await builder.commit();
      logger.info('updating referral submited', { ...writeBackContext, noPHI: true });
      trackTargetProviderSelectedWithVim({
        selectedProvider: referralToUpdate.targetProvider,
        status: ActionStatus.SUCCESS,
      });
      onClose();
    } catch (error) {
      logger.error('writeback failed', { error, ...writeBackContext, noPHI: true });
      trackTargetProviderSelectedWithVim({
        selectedProvider: referralToUpdate.targetProvider,
        status: ActionStatus.FAILED,
      });
      return dispatch({
        type: GlobalStateActionType.SET_SCREEN,
        payload: {
          screen: Screens.WriteBackError,
        },
      });
    }

    const submittedReferralData = referralToUpdate;
    const { basicInformation, targetProvider, conditions, procedureCodes } = submittedReferralData;
    analyticsClient.track(OptumReferralAppEventsEnum.RU_REFERRAL_SUBMITTED, {
      submittedReferralData: {
        optumReferralId: state.optumIframeId,
        targetProvider: targetProvider,
        specialty: basicInformation?.specialty,
        diagnosis: conditions?.diagnosis,
        startDate: basicInformation?.startDate,
        endDate: basicInformation?.endDate,
        priority: basicInformation?.priority,
        cpts: procedureCodes?.cpts,
        numberOfVisits: basicInformation?.numberOfVisits,
      },
    });
  };

  const onOpen = async () => {
    if (!referral || !patient || !appConfig) {
      return;
    }
    const onOpenContext = {
      referralId: referral.identifiers?.vimReferralId,
      ehrReferralId: referral.identifiers?.ehrReferralId,
      patientId: patient?.identifiers?.vimPatientId,
      insurance: patient?.insurance?.ehrInsurance,
    };
    logger.info('Before app open', { noPHI: true, ...onOpenContext });
    analyticsClient.track(OptumReferralAppEventsEnum.VIM_SELECT_BUTTON_CLICKED, {
      referral: currentReferral,
    });
    dispatch({
      type: GlobalStateActionType.SET_SCREEN,
      payload: {
        screen: Screens.Loading,
      },
    });

    let patientProblemList: PatientProblemList;
    try {
      logger.info('Start getting patientProblemList', { noPHI: true, ...onOpenContext });
      patientProblemList = await ehrState.patient?.getProblemList();
    } catch {
      logger.error('Error getting patient problem list', { ...onOpenContext, noPHI: true });
    }

    try {
      const data = await logic.referralRequest({
        referral,
        patient,
        deployConfig: appConfig.optumDeployConfiguration,
        problemList: patientProblemList,
      });
      logger.info('Got referral data from optum', {
        noPHI: true,
        optumIframeId: data!.referenceId,
        ...onOpenContext,
      });
      if (!data) {
        logger.error('Getting invalid optum response', {
          response: data,
          ...onOpenContext,
          noPHI: true,
        });
        throw new Error('Getting invalid optum response');
      }
      dispatch({
        type: GlobalStateActionType.SET_SCREEN,
        payload: {
          url: data!.redirectUrl,
          screen: Screens.OptumSearchProvider,
          optumIframeId: data!.referenceId,
        },
      });
    } catch (error) {
      logger.error('Failed getting optum response', { error, ...onOpenContext, noPHI: true });
      return dispatch({
        type: GlobalStateActionType.SET_SCREEN,
        payload: {
          url: null,
          screen: Screens.OptumSearchProvider,
        },
      });
    }
  };

  const onClose = () => {
    logger.info('Before app close', { noPHI: true, shouldStopDisableEnableapp });
    if (!shouldStopDisableEnableapp) {
      setActivationStatus(ActivationStatus.DISABLED);
      setActivationStatus(ActivationStatus.ENABLED);
    }
    setActive(false);
    dispatch({ type: GlobalStateActionType.SET_SCREEN, payload: { screen: Screens.None } });
    setOuAppLoaded(false);
  };

  // ----------------------------------------
  // Events handler
  // ----------------------------------------
  useEffect(() => {
    switch (state.lastAction?.type) {
      case GlobalStateActionType.ON_OPEN: {
        onOpen();
        break;
      }
      case GlobalStateActionType.ON_SUBMIT: {
        onReferralSubmit();
        break;
      }
      case GlobalStateActionType.ON_CLOSE: {
        onClose();
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.lastAction]);

  const trackTargetProviderSelectedNative = (
    previousProvider: Provider | undefined,
    currentProvider: Provider,
  ) => {
    if (waitingForVimProviderEhrUpdate) {
      setWaitingForVimProviderEhrUpdate(false);
      return;
    }

    analyticsClient.track(
      OptumReferralAppEventsEnum.TARGET_SELECTED_NATIVE,
      getProviderAnalyticsProperties(previousProvider, currentProvider),
    );
  };

  const trackTargetProviderSelectedWithVim = ({
    selectedProvider,
    status,
  }: {
    selectedProvider: UpdatableReferral['targetProvider'];
    status: ActionStatus;
  }) => {
    const previousProvider = currentReferral?.targetProvider;

    analyticsClient.track(OptumReferralAppEventsEnum.TARGET_SELECTED_WITH_VIM, {
      ...getProviderAnalyticsProperties(previousProvider, selectedProvider),
      actionDetails: {
        status,
        selectAction: SelectAction.WRITEBACK,
      },
    });
  };
  // track patient in context event
  useEffect(() => {
    logger.info('Before send app enabled event', {
      shouldSendAppEnabledEvent,
      ouAppLoaded,
      noPHI: true,
    });
    if (!shouldSendAppEnabledEvent) {
      return;
    }
    if (!patient) {
      logger.info('App enabled even, Patient undefined', {
        patientUndefined: !patient,
        shouldSendAppEnabledEvent,
        noPHI: true,
      });
      return;
    }

    if (ouAppLoaded) {
      logger.info(`Send ${OptumReferralAppEventsEnum.APP_ENABLED} analityc event`, {
        shouldSendAppEnabledEvent,
        ouAppLoaded,
        ehr_insurance: patient?.insurance?.ehrInsurance,
        vim_patient_id: patient?.identifiers?.vimPatientId,
        noPHI: true,
      });
      analyticsClient.track(OptumReferralAppEventsEnum.APP_ENABLED, {
        ehr_insurance: patient?.insurance?.ehrInsurance,
        vim_patient_id: patient?.identifiers?.vimPatientId,
        patient_token: patient?.token,
        linked_ehr_user_name: user?.identifiers?.ehrUsername,
        organization_id: organization?.identifiers?.id,
        organization_name: organization?.identifiers?.name,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient, user, ouAppLoaded, organization, shouldSendAppEnabledEvent]);
};

export default useEhrStateHandlers;
